.ag-sidenav {
  width: 100%;

  &__link {
    font-size: 1rem;
    color: $stormGray;
    display: inline-block;
    text-transform: uppercase;
    transition: color 0.15s ease;
    font-weight: 500;
    letter-spacing: 0.03em;
    position: relative;
    padding: 0.5rem 0;

    @include media('<phone') {
      font-size: 0.875rem;
    }

    &--active {
      color: $primary;
      font-weight: 600;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background: $primary;
      }
    }

    &:hover:not(.ag-sidenav__link--active) {
      color: $ebony;
    }
  }
}

.ag-nav-slider {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 80px;
    height: 100%;
    background: linear-gradient(to right, rgba($white, 0), rgba($white, 0.9));
    right: 0;
    top: 0;
    pointer-events: none;
    z-index: 99;

    @include media('<phone') {
      width: 48px;
    }
  }

  .swiper-slide {
    width: auto;
  }
}
