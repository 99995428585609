// navigataion styles here
// .ag-nav__menu {
//   min-height: calc(100vh - 64px);
//   @apply absolute w-full p-6 z-10 overflow-scroll bg-white;
// }
.ag-nav__menu {
  @apply fixed flex z-50;
  top: 65px;
  left: 0;
  bottom: 0;
  right: 0;

  &__overlay {
    @apply fixed inset-0 bg-black bg-opacity-25;
    top: 65px;
  }

  &__content {
    @apply relative w-full p-6 z-10 bg-white shadow-xl pb-12 flex flex-col overflow-y-auto;
    max-width: 430px;
  }
}

.ag-nav__item {
  @apply flex h-full items-center px-5 text-sm font-medium tracking-tight transition-colors duration-200 ease-in-out hover:text-primary;

  &--active {
    @apply relative font-bold text-primary;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 1.9rem;
      width: calc(100% - 32px);
      height: 2.5px;
      background: $primary;
      transform: translateX(-50%);
    }
  }

  &__panel__container {
    @apply rounded-md shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden;
  }
}

.ag-header {
  &__enq {
    @apply relative w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto ml-auto;
    max-width: 430px;

    &__title {
      font-weight: 700;
      font-size: 1.125rem;
    }
  }
}
