.ag-about {
  &__hero {
    @apply container grid md:gap-x-5 md:grid-cols-12 mt-14 lg:mt-32;

    &__text {
      @apply ag-text--lg mt-4 lg:mt-2 mx-auto lg:mt-5 text-stormGray;
      max-width: 510px;
    }
  }

  &__team-image-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    border-radius: 8px;
  }
  &__team-image {
    @extend %image;
  }

  &__details {
    p:nth-last-of-type(n + 2) {
      margin-bottom: 2rem;
    }
  }

  &__director {
    &__image-container {
    }
    &__image {
      width: 100%;
      height: auto;
      object-fit: contain;

      @include media('<phone') {
        max-width: 220px;
        margin: 0 auto 32px auto;
      }
    }

    &__quote {
      max-width: 500px;
      position: relative;

      p {
        font-size: 1.125rem;
        line-height: 170%;
        color: $ebony;

        &:nth-last-of-type(n + 2) {
          margin-bottom: 0.75rem;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        left: -44px;
        top: -15px;
        background: url(../../assets/icons/quote.svg);
      }

      @include media('<phone') {
        width: calc(100% - 44px);
        margin-left: 36px;

        p {
          font-size: 1.0625rem;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          left: -36px;
          top: -15px;
          background: url(../../assets/icons/quote.svg);
          background-size: contain;
        }
      }
    }

    &__name {
      font-size: 1.125rem;
      line-height: 127%;
      font-weight: 500;
      color: $ebony;

      @include media('<phone') {
        font-size: 1.0625rem;
      }
    }
    &__title {
      font-size: 1rem;
      line-height: 175%;
      color: $stormGray;
      @include media('<phone') {
        font-size: 0.875rem;
      }
    }
  }
}
