// button styles here
.btn-primary--small {
  @apply text-sm bg-primary rounded-md text-white whitespace-nowrap font-medium transition-colors duration-150 ease-in-out hover:bg-primary-hover;
  line-height: 1.2;
  padding: 14px 20px;
}

.btn-primary {
  @apply text-base bg-primary items-center rounded-md whitespace-nowrap text-white font-medium transition-colors duration-150 ease-in-out hover:bg-primary-hover;
  line-height: 1.4;
  padding: 1em 2.28em;
}

.btn-secondary {
  @apply text-base bg-white rounded-lg whitespace-nowrap text-ebony font-medium;
  line-height: 1;
  padding: 1em 1.5em;
  border: 2px solid $light-gray;
  transition: background 0.15s ease;

  &:hover {
    background: $offwhite;
  }
}

.btn-secondary--call {
  @extend .btn-primary--small;

  background-color: $white;
  border: 2px solid $light-gray;
  padding: 12px 18px 12px 16px;
}

.ag-btn-underline {
  @apply inline-block font-medium relative text-primary;
  padding-bottom: 4px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1.5px;
    background: currentColor;
    left: 0;
    bottom: 0;

    transition: transform 0.15s ease;
    transform-origin: left;
  }

  &:hover {
    &::after {
      transform: scaleX(0);
      transform-origin: right;
    }
  }
}
