.ag-secondaryCTA {
  display: flex;
  background-color: $offwhite;

  &__image-container {
    width: calc(50% - 28px);
    height: 640px;
    position: relative;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
}
