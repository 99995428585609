.ag-contact {
  &__social {
    @apply flex;

    &__link {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      display: grid;
      place-items: center;
      border-radius: 4px;

      &:hover {
        background-color: #f3f3f4;

        .ag-contact__social__icon {
          fill: $primary;
        }
      }
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: $stormGray;
      transition: fill 0.15s ease;
    }
  }

  &__info {
    display: flex;

    &__icon-container {
      flex: 0 0 20px;
      margin-right: 20px;
    }

    &__icon {
      fill: $ebony;
    }
  }

  &__map-container {
    position: relative;
    width: 100%;
    padding-top: 45%;

    @include media('<phone') {
      padding-top: 100%;
    }
  }

  &__map {
    @extend %image;

    background: $light-gray;
  }
}
