.ag-product {
  &__content {
    max-width: 480px;
    margin-top: calc(4.6875vw - 30px);

    @include media('<tablet') {
      margin-top: 100px;
      max-width: 100%;
    }
    @include media('<phone') {
      margin-top: 60px;
    }
  }
  &__image-container {
    position: relative;
    width: 100%;
    padding-top: 75%;
    overflow: hidden;
    border-radius: 0.5rem;
  }

  &__image {
    @extend %image;
  }
}
