.otr-sc {
  display: inline-block;
  font-size: 0.875rem;
  padding: 8px 0;
  color: $stormGray;
  line-height: 1.5;
  cursor: pointer;
  @include media('<phone') {
    font-size: 1rem;
  }

  &__container {
    position: relative;
  }
  &__name {
    display: inline-flex;
    opacity: 1;
    color: transparent;
    position: relative;
    overflow: hidden;

    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      color: $stormGray;
      transition: transform 0.8s $expo-ease-out;
    }
  }
  &__icon {
    height: 32px;
    width: auto;
    position: absolute;
    left: 2px;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%) scale(0.9) rotate(16deg);
    transition: transform 1s $expo-ease-out, opacity 0.4s $expo-ease-out;
  }

  &:hover {
    .otr-sc__icon {
      opacity: 1;
      transform: translateY(-50%) scale(1) rotate(0deg);
    }

    .otr-sc__name::after {
      transform: translateY(140%) skewY(30deg);
    }
  }
}
