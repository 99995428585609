.ag-figure {
  position: relative;
  width: 100%;

  img {
    @extend %image;
  }
}

.ag-figure--1-1 {
  @extend .ag-figure;
  padding-top: 100%;
}

.ag-figure--110p {
  @extend .ag-figure;
  padding-top: 110%;
}
.ag-figure--16-9 {
  @extend .ag-figure;
  padding-top: 56.25%;
}
