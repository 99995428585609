.ag-hero {
  position: relative;
  display: flex;
  align-items: center;
  background: $offwhite;

  @include media('<tablet') {
    // min-height: calc(100vh - 64px);
    flex-direction: column;
  }
  @include media('>=tablet') {
    // min-height: calc(100vh - 96px);
  }

  &__content {
    padding-top: 160px;
    padding-bottom: 120px;

    @include media('<tablet') {
      padding-top: 100px;
      padding-bottom: 40px;
      text-align: center;
    }

    @include media('<phone') {
      text-align: left;
    }
  }

  &__heading {
    font-size: 3.5rem;
    line-height: 0.96;
    font-weight: 600;
    color: $ebony;
    letter-spacing: -0.03em;

    @include media('<phone') {
      font-size: 2.8rem;
      line-height: 1.3;
    }
  }

  &__subtext {
    max-width: 480px;
  }

  &__image-container {
    position: absolute;
    width: 60%;
    top: 48px;
    bottom: -160px;
    left: auto;
    right: 0;

    pointer-events: none;

    @include media('<tablet') {
      position: relative;
      width: 80%;
      top: 0;
      bottom: 0;
    }
    @include media('<phone') {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.ag-brand-logo {
  height: auto;
  width: auto;

  max-width: 200px;
  max-height: 70px;

  @include media('<phone') {
    max-width: 130px;
    max-height: 50px;
  }
}

.ag-brand-slider {
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    width: auto;
  }

  &__prev,
  &__next {
    svg {
      width: 36px;
      height: 36px;

      path {
        fill: $ebony;
        transition: fill 0.15s ease;
      }

      &:hover {
        path {
          fill: $primary;
        }
      }
    }
  }
}
