// typography styles here
.ag-heading--one {
  font-size: 3.25rem;
  line-height: 1;
  font-weight: 600;
  color: $ebony;

  @include media('<phone') {
    font-size: 2.7rem;
  }
}

.ag-heading--one--light {
  @extend .ag-heading--one;

  color: $white;
}

.ag-heading--two {
  font-size: 2rem;
  color: $ebony;
  line-height: 130%;

  @include media('<phone') {
    font-size: 1.7rem;
  }
}

.ag-heading--three {
  font-size: 1.5rem;
  line-height: 130%;
  color: $ebony;

  @include media('<phone') {
    font-size: 1.3rem;
    line-height: 140%;
  }

  &.has-line {
    position: relative;
    padding-bottom: 1rem;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;

      height: 4px;
      width: 64px;

      background: $primary;
    }
  }
}

.ag-heading--three--light {
  @extend .ag-heading--three;

  color: $white;
}

.ag-points-title {
  font-size: 1.25rem;
  line-height: 2rem;
}

.ag-title--small {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: $primary-red;

  &.is-black {
    color: $ebony;
  }
}

.ag-title--small--light {
  @extend .ag-title--small;

  color: $white;
}

.ag-sub-title {
  font-size: 0.875rem;
  color: $stormGray;
  line-height: 1.24rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: 500;
}

.ag-text {
  font-size: 1rem;
  line-height: 160%;

  @include media('<phone') {
    font-size: 0.9375rem;
  }
}

.ag-text--lg {
  font-size: 1.125rem;
  line-height: 170%;

  @include media('<phone') {
    font-size: 16px;
  }
}

.ag-text--xl {
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: -0.03em;

  @include media('<phone') {
    font-size: 1.3rem;
    line-height: 140%;
  }
}

.ag-link--more {
  font-size: 1rem;
  color: $primary;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
}
