.ag-footer {
  background: $offwhite;
  font-size: 0.875rem;

  &__top {
    @apply container grid grid-cols-12;
    padding-top: 6.25rem;
    padding-bottom: 8.75rem;

    @include media('<phone') {
      padding-bottom: 6rem;
    }
  }

  &__logo {
    height: 56px;
    width: auto;
    object-fit: contain;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
    color: $ebony;
    margin-bottom: 0.5rem;
  }

  &__text {
    font-size: 0.875rem;
    color: $stormGray;
    line-height: 1.6;
  }

  &__link {
    @apply hover:text-ebony;

    color: $stormGray;

    font-size: 0.875rem;
    line-height: 1.6;
    transition: color 0.15s ease;
  }

  &__social {
    @apply flex gap-x-2;
  }

  &__social__link {
    display: inline-block;
  }

  &__social__icon {
    width: 2rem;
    height: 2rem;
    fill: $stormGray;
    transition: fill 0.15s ease;

    &:hover {
      fill: $primary;
    }
  }

  &__icon-content {
    @apply flex gap-x-2;
    color: $stormGray;
  }

  &__bot {
    @apply container flex md:items-center md:justify-between py-8 text-sm;
    color: $stormGray;
    border-top: 1px solid #ccc;

    @include media('<phone') {
      @apply flex-col gap-y-2;
    }
  }
}
