.ag-section {
  margin-bottom: 11.25rem;

  @include media('<tablet') {
    margin-bottom: 7rem;
  }

  @include media('<phone') {
    margin-bottom: 5rem;
  }
}

.ag-section--small {
  margin-bottom: 7.5rem;

  @include media('<tablet') {
    margin-bottom: 5rem;
  }

  @include media('<phone') {
    margin-bottom: 3.5rem;
  }
}

.ag-section--bg {
  @extend .ag-section;

  padding: 11.25rem 0;

  @include media('<tablet') {
    padding: 6.5rem 0;
  }

  @include media('<phone') {
    padding: 4rem 0;
  }
}

.ag-section--no-margin {
  margin-bottom: 0;
}
