.ag-breadcrumbs {
  display: flex;
  margin: 32px 0;
  padding: 16px 0;
  font-size: 0.875rem;

  @include media('<phone') {
    margin: 16px 0;
    padding: 16px 0;
    overflow-x: scroll;
  }

  &__item {
    display: flex;
    align-items: center;

    &:first-child {
      .ag-breadcrumbs__link {
        padding-left: 0;
      }
    }

    & + &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-image: url(../../assets/icons/Chevron.svg);
    }
  }

  &__link {
    padding: 0 0.75em;
    color: $light-gray;
    transition: color 0.15s ease;
    white-space: nowrap;

    &--active {
      color: $ebony;
      font-weight: 500;
      pointer-events: none;
    }

    &:hover:not(.ag-breadcrumbs__link--active) {
      color: $ebony;
    }

    @include media('<phone') {
      font-size: 12px;
    }
  }
}
