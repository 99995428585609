.ag-table {
  &__head {
    @apply px-3 py-8 border-b border-solid  text-left  border-borderColor text-sm font-bold text-ebony whitespace-nowrap;
  }

  &__data {
    @apply px-3 py-8 border-b border-solid text-center border-borderColor bg-white text-sm;

    &--head {
      @apply font-bold;
    }
  }
}

.ag-table__container {
  table {
    @apply min-w-full leading-normal;
  }

  th {
    @apply px-3 py-8 border-b border-solid  text-left  border-borderColor text-sm font-bold text-ebony whitespace-nowrap;
  }

  td {
    @apply px-3 py-8 border-b border-solid text-center border-borderColor bg-white text-sm;

    // &--head {
    //   @apply font-bold;
    // }
  }
}
