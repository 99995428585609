.ag-card {
  &__link {
    display: block;

    &:hover {
      .ag-card__image {
        transform: scale(1.1);
      }

      .ag-card__title::after {
        width: 100%;
      }
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 6px;
    overflow: hidden;
  }

  &__image {
    @extend %image;

    transition: transform 2s $ease-out-expo;
  }
  &__icon-container {
    width: 88px;
    height: 88px;
    display: grid;
    place-items: center;
    border-radius: 6px;
    background: $offwhite;
  }
  &__icon {
    width: 32px;
    height: 32px;
    object-fit: contain;

    @include media('<phone') {
      width: 48px;
      height: 48px;
    }
  }

  &__body {
    margin-top: 1.5rem;

    @include media('<phone') {
      margin-top: 0.75rem;
    }
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 140%;
    color: $ebony;
    position: relative;
    display: inline-block;

    @include media('<phone') {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 160%;
    color: $stormGray;
    margin-top: 1rem;

    @include media('<phone') {
      font-size: 0.875rem;
    }
  }
}

.ag-card--product {
  position: relative;

  .ag-card__image-container {
    padding-top: 105%;
    position: relative;
  }

  .ag-card__body {
    margin-top: 1.25rem;
  }

  .ag-card__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: $ebony;
    position: relative;
    padding-bottom: 0;
    display: inline-block;

    &::after {
      content: none;
    }
  }
}

.ag-card--horizontal {
  @apply flex gap-x-4 items-center;

  .ag-card__image-container {
    position: relative;
    width: 60px;
    height: 60px;
    padding-top: 0;
    overflow: hidden;
  }
  .ag-card__image {
  }

  .ag-card__title {
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 0;
    display: inline-block;

    &::after {
      content: none;
    }
  }
}
